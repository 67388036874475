/* eslint-disable */
import React from 'react';
import { NavLink } from 'react-router-dom';
import cambioClave from '../../../images/cambio-clave.png';
import dahboardLogo from '../../../images/dashboardMenu.svg';
import imageLogo from '../../../images/ideasMenu.svg';
import distribution from '../../../images/distribution.svg';
import preferences from '../../../images/preferences.png';
import users from '../../../images/users.svg';
import logo from '../../../images//logo-transparent.png';
import config from '../../../images/config.svg';
import supportLogo from '../../../images/patrocinante.svg';
import evaluationHistory from '../../../images/evaluationHistory.svg';
import gestionUsuarios from '../../../images/gestion-usuarios.png';
import MencionEspecial from '../../../images/MencionEspecial.svg';
import documentLogo from '../../../images/document.png';
import form from '../../../images/form.svg';
import wiki from '../../../images/wiki.svg';
import evaluators from '../../../images/evaluators.svg';
import reports from '../../../images/reports.svg';
import carbon_enterprise from '../../../images/carbon_enterprise.svg';
import './Sidebar.scss';

const wikiLink =
  'https://drive.google.com/drive/folders/1E27AxHyeQOmJJ4htk71MCC8ajez4wRlC';

const judgeLink=
'https://drive.google.com/drive/folders/1JS1XVTcrXZwfvjSOxfjCaWAcILiV8HRY';

// Link to ideas list option in the dashbboard
export const SidebarElement = (props) => {
  const { restoreAllForms, url, image, label } = props;
  let colorSelected = '';
  if (url === window.location.pathname) {
    colorSelected = '#3e4266';
  }
  // 3e4266
  return (
    <NavLink to={url}>
      <div
        style={{ backgroundColor: colorSelected }}
        onClick={restoreAllForms}
        className="menu-option"
      >
        <img className="sidebar-image" src={image} alt="image" />
        <h6>{label}</h6>
      </div>
    </NavLink>
  );
};

export const ParticipantSupport = (props) => {
  const { restoreAllForms } = props;
  return (
    // <NavLink to={`/home/${userSession.userType}/change-password`}>
    <a href={wikiLink} target="_blank">
      <div onClick={restoreAllForms} className="menu-option">
        <img src={wiki} alt="wiki" />
        <h6>Apoyo al Participante</h6>
      </div>
    </a>
    // </NavLink>
  );
};

export const JudgeSupport = (props) => {
  const { restoreAllForms } = props;
  return (
    <a href={judgeLink} target="_blank">
      <div onClick={restoreAllForms} className="menu-option">
        <img src={documentLogo} alt="documents" />
        <h6>Documentos Criterios Evaluación</h6>
      </div>
    </a>
  );
}

// Menu sidebar component
export const Sidebar = (props) => {
  const { userSession, restoreAllForms, updateAdminDashboard } = props;

  // Admin Profile Navlinks
  if (userSession.userType == 'participant') {
    return (
      <React.Fragment>
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={'/home/participant'}
          image={dahboardLogo}
          label={'Dashboard'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/${userSession.userType}/ideas`}
          image={imageLogo}
          label={'Ideas'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/${userSession.userType}/change-password`}
          image={gestionUsuarios}
          label={'Cambiar contraseña'}
        />
        <ParticipantSupport
          userSession={userSession}
          restoreAllForms={restoreAllForms}
        />
      </React.Fragment>
    );
  }
  // Insurance Profile Navlinks
  if (userSession.userType === 'admin') {
    return (
      <React.Fragment>
        <div onClick={() => updateAdminDashboard()}>
          <SidebarElement
            restoreAllForms={restoreAllForms}
            url={`/home/admin`}
            image={dahboardLogo}
            label={'Dashboard'}
          />
        </div>
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/${userSession.userType}/ideas`}
          image={imageLogo}
          label={'Ideas'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={'/home/admin/idea-management'}
          image={imageLogo}
          label={'Gestión de Evaluaciones'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/${userSession.userType}/user-management`}
          image={gestionUsuarios}
          label={'Gestión de Usuarios'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={'/home/admin/evaluators-management'}
          image={evaluators}
          label={'Gestión de Evaluadores'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={'/home/admin/idea-distribution/general-info'}
          image={distribution}
          label={'Distribución'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/${userSession.userType}/preferences/contests`}
          image={preferences}
          label={'Preferencias'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/admin/special-mention`}
          image={MencionEspecial}
          label={'Mención Especial'}
        />{' '}
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/admin/company-management`}
          image={carbon_enterprise}
          label={'Gestión de Empresas'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/admin/reports`}
          image={reports}
          label={'Reportes'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/admin/change-password`}
          image={gestionUsuarios}
          label={'Cambiar contraseña'}
        />
      <JudgeSupport
          userSession={userSession}
          restoreAllForms={restoreAllForms}
        />
      </React.Fragment>
    );
  }
  // Government Profile Navlinks
  if (userSession.userType === 'judge') {
    return (
      <React.Fragment>
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={'/home/judge'}
          image={dahboardLogo}
          label={'Dashboard'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={'/home/judge/evaluations'}
          image={imageLogo}
          label={'Evaluaciones'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/judge/change-password`}
          image={gestionUsuarios}
          label={'Cambiar contraseña'}
        />{' '}
      <JudgeSupport
          userSession={userSession}
          restoreAllForms={restoreAllForms}
        />
      </React.Fragment>
    );
  }
  if (userSession.userType === 'judge_leader') {
    return (
      <React.Fragment>
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={'/home/judge'}
          image={dahboardLogo}
          label={'Dashboard'}
        />{' '}
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={'/home/judge/evaluations'}
          image={form}
          label={'Evaluaciones'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={'/home/judge/idea-management'}
          image={imageLogo}
          label={'Gestión de Evaluaciones'}
        />{' '}
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={'/home/judge/evaluators-management'}
          image={evaluators}
          label={'Gestión de Evaluadores'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/judge/change-password`}
          image={gestionUsuarios}
          label={'Cambiar contraseña'}
        />
       <JudgeSupport
          userSession={userSession}
          restoreAllForms={restoreAllForms}
        />
      </React.Fragment>
    );
  }
  if (userSession.userType === 'judge_special') {
    return (
      <React.Fragment>
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/judge/special-mention`}
          image={MencionEspecial}
          label={'Mención Especial'}
        />
        <SidebarElement
          restoreAllForms={restoreAllForms}
          url={`/home/judge/change-password`}
          image={gestionUsuarios}
          label={'Cambiar contraseña'}
        />
        <JudgeSupport
          userSession={userSession}
          restoreAllForms={restoreAllForms}
        />
      </React.Fragment>
    );
  } else {
    return null;
  }
};

// Responsive Sidebar
export const ResponsiveSidebar = (props) => {
  const {
    sidebarShow,
    handleSideBarShow,
    userSession,
    restoreAllForms,
    updateAdminDashboard,
  } = props;
  if (sidebarShow) {
    return (
      <div onClick={handleSideBarShow} className="responsive-sidebar">
        <div className="responsive-sidebar-header">
          <div>
            <img src={logo} alt="" />
          </div>
          <h6 onClick={handleSideBarShow}>X</h6>
        </div>
        <Sidebar
          userSession={userSession}
          restoreAllForms={restoreAllForms}
          updateAdminDashboard={updateAdminDashboard}
        />
      </div>
    );
  } else {
    return null;
  }
};
